define('samplePcrWellView',[
    'module',
    'marionette',
    'template!samplePcrWellView',
    'wellUtils',
    'underscore',
    'linkNewTabView'
], function (
    module,
    Marionette,
    WellTargetTpl,
    WellUtils,
    _,
    LinkNewTabView
) {
    'use strict';

    module.exports = Marionette.LayoutView.extend({
        template: WellTargetTpl,

        modelEvents: {
            'sync': 'renderSafe'
        },

        regions: {
            pcrRun: '.js-pcrrun'
        },

        className: 'col-w-all col-w-100 d-f bottom-border-cell-light',

        renderSafe: function () {
            if (!this.isDestroyed) {
                this.render();
            }
        },

        onRender: function () {
            var params = {
                context: this,
                placement: 'bottom'
            };
            params.target = this.$el;

            WellUtils.showPcrWellPopover(params);
            this.getRegion('pcrRun').show(new LinkNewTabView(this.model.get('refPcrRun').getEntity().parameterForLink(this.model.get('refPcrRun'))));
        },

        serializeData: function () {
            var templateData = {};
            templateData.secId = this.model.get('secId');
            templateData.runSecId = this.model.get('runSecId');
            templateData.pos = this.model.get('pos');
            templateData.refAssay = this.model.get('refAssay') ? this.model.get('refAssay').get('code') : '-';
            templateData.results = [];
            this.model.get('results').each(_.bind(function (result) {
                var resultForTemplateData = {};
                result.targetCode = result.get('mbAna') ? result.get('mbAna').get('code') : '';
                result.cts = result.get('cts');
                result.quantifications = result.get('quantifications');
                result.resCode = result.get('result') ? result.get('result').get('code') : '';
                resultForTemplateData.iconHtml = result.getResultIcon(true).iconHtml;
                templateData.results.push(resultForTemplateData);
            }, this));
            return templateData;
        }
    });
});
